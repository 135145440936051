<template>
  <c-flex flex-dir="column" backgroundColor="#ffffff">
    <Header>
      <template #title>
        <c-flex>
          <c-link as="router-link" to="/">
            <c-image
              :src="require('@/assets/icon-chevron-left.svg')"
              alt="icon back"
            />
          </c-link>
          <h1 v-chakra flex="1" ml="15px">Meal Plan</h1>
        </c-flex>
      </template>
      <template #additional-icon>
        <c-button
          variant="ghost"
          w="24px"
          h="24px"
          min-w="24px"
          min-h="24px"
          m="0"
          p="0"
          @click="
            $router.replace({ name: 'meal-plan', query: { intro: true } })
          "
        >
          <c-image
            :src="require('@/assets/icon-help-filled.svg')"
            alt="icon help"
          />
        </c-button>
      </template>
    </Header>

    <c-flex
      :flex-dir="['column', 'row-reverse']"
      max-w="1200px"
      :min-w="[null, '1200px']"
      :mx="[null, 'auto']"
      mt="20px"
    >
      <RouterView />
      <Navigation />
    </c-flex>

    <Footer />

    <PopupIntro v-if="showIntro && programId == null" @close="showIntro = false" />
  </c-flex>
</template>

<script>
import { mapState, mapActions } from "vuex"
import Header from "@/components/header/index.vue"
import Navigation from "./navigation.vue"
import Footer from "@/components/Footer.vue"
import PopupIntro from "./popup-intro.vue"

export default {
  name: "MealPlanLayout",
  props: ["isNutritionist"],
  components: {
    Header,
    Navigation,
    Footer,
    PopupIntro,
  },
  data() {
    return {
      showPopupIntro: false,
    }
  },
  computed: {
    ...mapState({
      latestProgram: (s) => s.mealPlan.latestProgram,
      rencanaMakan: (s) => [
        ...s.mealPlan.rencanaMakan,
        ...s.clients.rencanaMakan,
      ],
    }),
    showIntro: {
      get() {
        if (this.$route.query.intro != null) return true
        return false
      },
      set(val) {
        if (val === false) {
          this.$router.replace({ query: { ...this.$route.query, intro: undefined } })
        } else {
          this.$router.replace({ query: { ...this.$route.query, intro: true } })
        }
      },
    },
  },
  watch: {
    showPopupIntro: {
      immediate: true,
      handler(val) {
        this.showPopupIntro = val
      },
    },
  },
  async mounted() {
    let role = this["auth::role"]

    if (role === "nutritionist" && this.clientId != null) {
      let program = await this.$store.dispatch(
        "mealPlan/getLatestProgramForNutri",
        this.clientId
      )
      await this.listDetail({ clientId: this.clientId, programId: program.id })
    } else if (this.programId != null) {
      this.getRencanaMakan(this.programId);
    } else {
      let program = await this.$store.dispatch(
        "mealPlan/getLatestProgramForClient"
      )
      if (program) {
        this.getRencanaMakan(program.id)
      }
    }

    // Popup Intro
    this.showIntro = this.popupIntro()
    console.log("show popup intro?", this.showPopupIntro)
  },
  methods: {
    ...mapActions({
      listDetail: "mealPlan/list",
      getRencanaMakan: "clients/getRencanaMakan",
    }),
    popupIntro() {
      let hasProgram = this.latestProgram != null
      let hasRencanaMakan = this.rencanaMakan.length <= 0
      let hasIntro = this.$route.query.intro != null

      console.log("popupIntro", { hasProgram, hasRencanaMakan, hasIntro })

      if (hasIntro || hasRencanaMakan) return true
      if (!hasProgram) return false

      let items = this.$store.getters["localConfig/getItem"](
        "popup-intro-rencana-makan",
        []
      )
      return items.includes(this.latestProgram?.id) === false
    },
  },
}
</script>
