var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('c-flex',{attrs:{"as":"nav","id":"bottomNavigation","pos":['fixed', 'sticky'],"bottom":['0', 'normal'],"left":"0","h":['60px', '100vh'],"w":['100vw', '360px'],"bg":"white","align-items":['center', 'flex-start'],"justify-content":['space-around', 'normal'],"d":"flex","flex-shrink":"0","flex-direction":['row', 'column'],"z-index":['1000', 1],"box-shadow":['4px -4px 50px 5px rgba(0, 0, 0, 0.15)', 'none'],"border-right":['none', '1px solid #F2F2F2']}},[(_vm.isMenuActive)?_c('portal',{attrs:{"to":"main"}},[_c('modal-menu')],1):_vm._e(),(_vm.isNutri && _vm.client != null)?_c('c-flex',{attrs:{"mt":"25px","mb":"30px"}},[_c('c-image',{attrs:{"h":"72px","w":"72px","border-radius":"55px","src":"https://via.placeholder.com/72","alt":_vm.client.firstName}}),_c('c-flex',{attrs:{"flex":"1","flex-dir":"column","ml":"20px"}},[_c('c-text',{attrs:{"font-family":"heading","font-size":"18px","font-weight":"600","line-height":"27px"}},[_vm._v(" "+_vm._s(_vm.client.firstName)+" "+_vm._s(_vm.client.lastName)+" ")]),_c('c-text',{attrs:{"color":"brand.900","font-size":"14px","font-weight":"400","line-height":"21px","text-align":"justified"}},[_vm._v(" "+_vm._s(_vm.client.phone)+" ")])],1)],1):_vm._e(),_vm._l((_vm.menus),function(menu){return [_c('c-link',{directives:[{name:"chakra",rawName:"v-chakra",value:({
        '&.active': {
          bg: [null, '#f2f2f2'],
        },
      }),expression:"{\n        '&.active': {\n          bg: [null, '#f2f2f2'],\n        },\n      }"}],key:menu.name,class:{ 'menu-item': true, active: menu.isActive },attrs:{"as":menu.route != null ? 'router-link' : 'button',"to":menu.route,"exact-active-class":"active","d":"flex","flex-dir":['column', 'row'],"align-items":"center","justify-content":['center', 'flex-start'],"h":['auto', '66px'],"w":['auto', '328px'],"p":[0, '10px'],"my":[0, '5px'],"mr":[0, '10px'],"border-radius":[0, '12px']},on:{"click":function($event){return _vm.onMenuClicked(menu)}}},[_c('c-image',{staticClass:"icon",attrs:{"alt":menu.name,"src":menu.icon,"w":"24px","h":"24px"}}),_c('c-text',{directives:[{name:"chakra",rawName:"v-chakra",value:({
          '&.active': {
            color: 'brand.900',
          },
        }),expression:"{\n          '&.active': {\n            color: 'brand.900',\n          },\n        }"}],class:{ active: menu.isActive },attrs:{"ml":[0, '10px'],"font-size":['10px', '16px'],"font-weight":"500","color":"#c4c4c4"}},[_vm._v(" "+_vm._s(menu.name)+" ")])],1)]})],2)}
var staticRenderFns = []

export { render, staticRenderFns }